.app {
  display: flex;
  flex-direction: column;

  position: relative;

  min-height: 100vh;
}

.main {
  flex: 1;
}
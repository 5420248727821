$linear-gradient--alpha: linear-gradient(60deg, #ED43FF, #7444FE);
$linear-gradient--beta: linear-gradient(60deg, #00DBCC, #9153FF);
$linear-gradient--gamma: linear-gradient(75deg, #00AEC9, #00E91F);

.button {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: bold;
  color: #fff;
  padding: 0.875rem 1.75rem;
  border-radius: 0.625rem;

  &--alpha {
    background: $linear-gradient--alpha;
  }

  &--beta {
    background: $linear-gradient--beta;
  }

  &--gamma {
    background: $linear-gradient--gamma;
  }
}
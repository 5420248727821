.contact-form {
  grid-row: 2;

  &__title {
    font-family: "Ubuntu", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: bold;
    color: #2e2e2e;
    padding-bottom: 0.5rem;
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 2rem;

    @media (max-width: 822px) {
      .Label,
      .Input:not(.Submit):not(.Textarea):not([type="checkbox"]) {
        flex-basis: 100%;
      }

      .Input:not(.Submit):not(.Textarea):not([type="checkbox"]) {
        height: 40px;
        width: 100%;
      }

      .Input:not(.Submit):not([type="checkbox"]) {
        border: 0;
        border-top: 1px solid #9e9e9e;
        outline: none;
        padding: 0 0.5rem;
        margin-bottom: 1.625rem;
      }

      .Input[type="checkbox"] {
        margin-left: 1rem;
        margin-bottom: 0.5rem;
      }

      .Textarea {
        flex-basis: 100%;
      }

      .Submit {
        cursor: pointer;
        border: none;
        padding: 0.875rem 3rem;
        margin: 2.8125rem auto 0 auto;
        border-radius: 0.625rem;
        background: linear-gradient(45deg, #55d500, #c9c900);
        font-family: "Ubuntu", sans-serif;
        font-size: 1.25rem;
        line-height: 1.6875rem;
        font-weight: bold;
        color: #fff;
      }

      .Label:nth-of-type(8),
      .Label:nth-of-type(9) {
        flex-basis: calc(100% - 2rem);
      }

      .Input:nth-of-type(7),
      .Input:nth-of-type(8) {
        flex-basis: 1rem;
      }

      // react-components:form styles:order
      .Label:nth-of-type(1),
      .Label:nth-of-type(2) {
        order: -13;
        color: #06b400;
        flex-basis: 41.4%;
      }

      // interrupt specificity
      .Input:nth-of-type(1):not([type="textarea"]):not(.Submit):not(.Textarea):not([type="checkbox"]),
      .Input:nth-of-type(2):not([type="textarea"]):not(.Submit):not(.Textarea):not([type="checkbox"]) {
        order: -12;
        flex-basis: 41.4%;
        border-color: #06b400;
      }

      .Label:nth-of-type(3) {
        order: -11;
      }

      .Input:nth-of-type(3) {
        order: -10;
      }
      .Label:nth-of-type(4) {
        order: -9;
      }
      .Input:nth-of-type(4) {
        order: -8;
      }

      .Label:nth-of-type(5) {
        order: -7;
      }

      .Input:nth-of-type(5) {
        order: -5;
      }
      .Label:nth-of-type(6) {
        order: -3;
        flex-basis: 41.4%;
      }
      .Input:nth-of-type(6).Input:nth-of-type(6) {
        order: -1;
        flex-basis: 41.4%;
      }

      .Label:nth-of-type(7) {
        order: -4;
        flex-basis: 41.4%;
      }

      .Textarea {
        order: -2;
        flex-basis: 41.4%;
        height: 40px;
      }

      .Label:nth-of-type(8) {
        order: 6;

        flex-basis: 41.4%;
        text-align: center;
      }
      .Input:nth-of-type(8),
      .Input:nth-of-type(7) {
        order: 2;
        width: 30px;
        height: 30px;
        display: block;
        margin: 0 19% 10px;
      }

      .Input:nth-of-type(7) {
        order: 3;
      }

      .Label:nth-of-type(9) {
        order: 5;
        flex-basis: 41.4%;
        text-align: center;
      }

      .Submit {
        order: 10;
      }
    }
  }
  // react-components:form styles
  .Label {
    font-family: "Ubuntu", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #2e2e2e;
    padding-bottom: 0.5rem;
  }

  // desctop
  @media (min-width: 822px) {
    .Label,
    .Input:not(.Submit):not(.Textarea):not([type="checkbox"]) {
      flex-basis: 41.4%;
    }

    .Input:not(.Submit):not(.Textarea):not([type="checkbox"]) {
      height: 40px;
      width: 100%;
    }

    .Input:not(.Submit):not([type="checkbox"]) {
      border-radius: 10px;
      border: 1px solid #9e9e9e;
      outline: none;
      padding: 0 0.5rem;
      margin-bottom: 1.625rem;
    }

    .Input[type="checkbox"] {
      margin-left: 1rem;
      margin-bottom: 0.5rem;
    }

    .Textarea {
      flex-basis: 100%;
    }

    .Submit {
      cursor: pointer;
      border: none;
      padding: 0.875rem 3rem;
      margin: 2.8125rem auto 0 auto;
      border-radius: 0.625rem;
      background: linear-gradient(45deg, #55d500, #c9c900);
      font-family: "Ubuntu", sans-serif;
      font-size: 1.25rem;
      line-height: 1.6875rem;
      font-weight: bold;
      color: #fff;
    }

    .Label:nth-of-type(8),
    .Label:nth-of-type(9) {
      flex-basis: calc(100% - 2rem);
    }

    .Input:nth-of-type(7),
    .Input:nth-of-type(8) {
      flex-basis: 1rem;
    }

    // react-components:form styles:order
    .Label:nth-of-type(1),
    .Label:nth-of-type(2) {
      order: -8;
    }

    // interrupt specificity
    .Input:nth-of-type(1):not([type="textarea"]):not(.Submit):not(.Textarea):not([type="checkbox"]),
    .Input:nth-of-type(2):not([type="textarea"]):not(.Submit):not(.Textarea):not([type="checkbox"]) {
      order: -7;
    }

    .Label:nth-of-type(3),
    .Label:nth-of-type(4) {
      order: -6;
    }

    .Input:nth-of-type(3),
    .Input:nth-of-type(4) {
      order: -5;
    }

    .Label:nth-of-type(5),
    .Label:nth-of-type(6).Label:nth-of-type(6) {
      order: -4;
    }

    .Input:nth-of-type(5),
    .Input:nth-of-type(6) {
      order: -3;
    }

    .Label:nth-of-type(7) {
      order: -2;
    }

    .Textarea {
      order: -1 !important;
      flex-basis: 100%;
    }

    .Input:nth-of-type(8).Input:nth-of-type(8).Input:nth-of-type(8),
    .Input:nth-of-type(7) {
      position: static;

      opacity: 1;
    }

    .Label:nth-of-type(8),
    .Input:nth-of-type(8) {
      order: 1;
    }

    .Label:nth-of-type(9),
    .Input:nth-of-type(9) {
      order: 2;
    }

    .Submit {
      order: 4;
    }

    & {
      grid-row: initial;
    }
  }
}

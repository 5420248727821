.nav-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  &__group {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;

    padding-top: 0.5rem;
  }

  &__group-title {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.3125rem;
    font-weight: bold;
    color: #6A6A6A;
  }

  &__link {
    font-family: 'Ubuntu', sans-serif;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    color: #6A6A6A;
    margin-top: 0.5rem;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #bbb;
    }
  }

  @media (min-width: 576px) {
    &__group {
      flex-basis: initial;
      display: flex;
      flex-direction: column;
      text-align: right
    }
  }
}
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900);

.nav {
  display: none;
  width: 100%;
  max-width: 576px; }
  @media (min-width: 664px) {
    .nav {
      display: flex;
      align-items: center; } }
  @media (min-width: 768px) {
    .nav {
      max-width: 698px; } }
  .nav__item {
    flex: 1 1; }
  .nav__link {
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: bold;
    color: #AEABAB;
    -webkit-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
    position: relative; }
    .nav__link:hover, .nav__link--active {
      color: #000000; }
    .nav__link::after {
      content: "";
      position: absolute;
      top: calc(100% + 0.5rem);
      left: 0;
      width: 100%;
      height: 4px;
      border-radius: 10px;
      background: transparent; }
    .nav__link--yellow::after {
      background: -webkit-linear-gradient(45deg, #FF2C00, #FFE600);
      background: linear-gradient(45deg, #FF2C00, #FFE600); }
    .nav__link--purple::after {
      background: -webkit-linear-gradient(75deg, #9D7BFF, #F275FF);
      background: linear-gradient(15deg, #9D7BFF, #F275FF); }
    .nav__link--blue::after {
      background: -webkit-linear-gradient(75deg, #8139FF, #00DBCC);
      background: linear-gradient(15deg, #8139FF, #00DBCC); }
    .nav__link--green::after {
      background: -webkit-linear-gradient(75deg, #00DBFE, #00E91F);
      background: linear-gradient(15deg, #00DBFE, #00E91F); }
    .nav__link + .nav__link {
      margin-right: 1.75rem; }
    @media (min-width: 576px) {
      .nav__link + .nav__link {
        margin-right: 3.75rem; } }
    @media (min-width: 768px) {
      .nav__link + .nav__link {
        margin-right: 5.75rem; } }
    @media (min-width: 1120px) {
      .nav__link + .nav__link {
        margin-right: 8.75rem; } }

.nav-mobile {
  padding: 1rem; }
  .nav-mobile__group {
    display: flex;
    flex-direction: column; }
    .nav-mobile__group > * {
      margin-bottom: 0.5rem; }
  .nav-mobile__group-title {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.3125rem;
    font-weight: bold;
    color: #6A6A6A; }
  .nav-mobile__link {
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    color: #6A6A6A;
    margin-right: 0.5rem; }

.button {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: bold;
  color: #fff;
  padding: 0.875rem 1.75rem;
  border-radius: 0.625rem; }
  .button--alpha {
    background: -webkit-linear-gradient(30deg, #ED43FF, #7444FE);
    background: linear-gradient(60deg, #ED43FF, #7444FE); }
  .button--beta {
    background: -webkit-linear-gradient(30deg, #00DBCC, #9153FF);
    background: linear-gradient(60deg, #00DBCC, #9153FF); }
  .button--gamma {
    background: -webkit-linear-gradient(15deg, #00AEC9, #00E91F);
    background: linear-gradient(75deg, #00AEC9, #00E91F); }

.mobile-menu-switch {
  display: block;
  border: none;
  background-color: transparent;
  width: 8vw;
  color: #fff; }
  .mobile-menu-switch__icon {
    color: #707070; }
  @media (min-width: 664px) {
    .mobile-menu-switch {
      display: none; } }

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2048;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out; }
  .mobile-menu--is-hidden {
    -webkit-transform: translate3d(100vw, 0, 0);
            transform: translate3d(100vw, 0, 0); }
  .mobile-menu--is-visible {
    -webkit-transform: translate3d(0vw, 0, 0);
            transform: translate3d(0vw, 0, 0); }
  .mobile-menu__close {
    position: absolute;
    top: 3vw;
    left: 3vw;
    z-index: 4096;
    background-color: transparent; }
  @media (min-width: 664px) {
    .mobile-menu {
      display: none; } }

.drop-down-menu {
  position: absolute;
  z-index: 4096;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  top: 129px;
  display: none; }
  .drop-down-menu__main {
    display: flex;
    align-items: center;
    padding: 0.8125rem 0; }
  .drop-down-menu__image {
    display: none; }
  .drop-down-menu__nav {
    width: 100%;
    max-width: 576px;
    display: flex; }
  .drop-down-menu__group {
    flex: 1 1;
    display: flex;
    flex-direction: column; }
  .drop-down-menu__link {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.3125rem;
    font-weight: bold;
    color: #707070;
    padding: 0.8125rem 0;
    -webkit-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out; }
    .drop-down-menu__link:hover {
      color: #aeabab; }
  @media (min-width: 664px) {
    .drop-down-menu {
      display: inline;
      display: initial; } }
  @media (min-width: 768px) {
    .drop-down-menu__nav {
      max-width: 698px; } }
  @media (min-width: 946px) {
    .drop-down-menu__image {
      display: inline;
      display: initial; } }

.header {
  background-color: #fff;
  padding: 0.55rem 0 0; }
  .header__main {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header__logo {
    max-width: 130px;
    display: inline-block;
    height: auto;
    -webkit-filter: contrast(105%);
            filter: contrast(105%); }

.nav-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between; }
  .nav-bottom__group {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 0.5rem; }
  .nav-bottom__group-title {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.3125rem;
    font-weight: bold;
    color: #6A6A6A; }
  .nav-bottom__link {
    font-family: 'Ubuntu', sans-serif;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    color: #6A6A6A;
    margin-top: 0.5rem;
    -webkit-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out; }
    .nav-bottom__link:hover {
      color: #bbb; }
  @media (min-width: 576px) {
    .nav-bottom__group {
      flex-basis: initial;
      display: flex;
      flex-direction: column;
      text-align: right; } }

.social-media {
  display: flex;
  align-items: center; }
  .social-media__link + .social-media__link {
    margin-right: 1.25rem; }

.footer {
  padding-top: 1.75rem;
  padding-bottom: 5.5rem;
  background-color: #fff; }

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.container--normal {
  max-width: 99.25rem; }

.container--wide {
  max-width: 103.5rem; }

.container__product {
  max-width: 83.8125rem;
  margin-top: 0.5rem;
  background: -webkit-linear-gradient(70deg, #ed43ff, #7444fe);
  background: linear-gradient(20deg, #ed43ff, #7444fe);
  border: solid 1px transparent;
  box-shadow: 0px 2px 13px 1px #555555;
  border-radius: 15px;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0; }
  .container__product--theme-purple {
    background: -webkit-linear-gradient(70deg, #ed43ff, #7444fe);
    background: linear-gradient(20deg, #ed43ff, #7444fe); }
  .container__product--theme-blue {
    background: -webkit-linear-gradient(70deg, #00dbcc, #9153ff);
    background: linear-gradient(20deg, #00dbcc, #9153ff); }
  .container__product--theme-green {
    background: -webkit-linear-gradient(70deg, #00e91f, #00aec9);
    background: linear-gradient(20deg, #00e91f, #00aec9); }

.text {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #2A2A2A; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

html {
  box-sizing: border-box;
  font-size: 100%; }

html,
body {
  height: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Ubuntu', 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400; }

a {
  color: inherit;
  text-decoration: none; }

ul {
  list-style: none; }

img {
  max-width: 100%;
  display: block;
  height: auto; }

button {
  border: none; }

button:hover {
  cursor: pointer; }

p {
  padding-bottom: 1.375rem; }

.not-found__main {
  text-align: center; }

.not-found__title, .not-found__note {
  font-family: 'Ubuntu', sans-serif; }

.not-found__title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: bold;
  padding: 1.8125rem 0;
  text-align: center; }

.not-found__note {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 300;
  text-align: center;
  color: #2A2A2A; }

@media (min-width: 576px) {
  .not-found__title {
    font-size: 1.5rem;
    line-height: 2rem; }
  .not-found__text {
    font-size: 1rem; } }

@media (min-width: 768px) {
  .not-found__title {
    font-size: 2rem;
    line-height: 2.6875rem; }
  .not-found__text {
    font-size: 1.125rem; } }

.copyright {
  font-family: 'Ubuntu', sans-serif;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: #707070;
  text-align: center;
  padding-top: 2.75rem;
  padding-bottom: 0; }
  @media (min-width: 576px) {
    .copyright {
      text-align: right; } }

.contact-us__header {
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 4.25rem; }

.contact-us__title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: bold;
  color: #2E2E2E;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  position: relative; }
  .contact-us__title::after {
    content: "";
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 50%;
    width: 122px;
    height: 7px;
    border-radius: 10px;
    background: -webkit-linear-gradient(45deg, #55D500, #C9C900);
    background: linear-gradient(45deg, #55D500, #C9C900);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }

.contact-us__subtitle {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: #2E2E2E;
  padding-top: 2rem; }

.contact-us__main {
  display: grid;
  grid-gap: 2.6875rem;
  align-items: center; }

@media (min-width: 426px) {
  .contact-us__title {
    font-size: 1.75rem;
    line-height: 2.5rem; } }

@media (min-width: 822px) {
  .contact-us__main {
    grid-gap: 5.375rem;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 8.75rem; } }

@media (min-width: 992px) {
  .contact-us__title {
    font-size: 3.75rem;
    line-height: 5rem; }
  .contact-us__main {
    grid-gap: 10.75rem; } }

.contact-form {
  grid-row: 2; }
  .contact-form__title {
    font-family: "Ubuntu", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: bold;
    color: #2e2e2e;
    padding-bottom: 0.5rem; }
  .contact-form__main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 2rem; }
    @media (max-width: 822px) {
      .contact-form__main .Label,
      .contact-form__main .Input:not(.Submit):not(.Textarea):not([type="checkbox"]) {
        flex-basis: 100%; }
      .contact-form__main .Input:not(.Submit):not(.Textarea):not([type="checkbox"]) {
        height: 40px;
        width: 100%; }
      .contact-form__main .Input:not(.Submit):not([type="checkbox"]) {
        border: 0;
        border-top: 1px solid #9e9e9e;
        outline: none;
        padding: 0 0.5rem;
        margin-bottom: 1.625rem; }
      .contact-form__main .Input[type="checkbox"] {
        margin-left: 1rem;
        margin-bottom: 0.5rem; }
      .contact-form__main .Textarea {
        flex-basis: 100%; }
      .contact-form__main .Submit {
        cursor: pointer;
        border: none;
        padding: 0.875rem 3rem;
        margin: 2.8125rem auto 0 auto;
        border-radius: 0.625rem;
        background: -webkit-linear-gradient(45deg, #55d500, #c9c900);
        background: linear-gradient(45deg, #55d500, #c9c900);
        font-family: "Ubuntu", sans-serif;
        font-size: 1.25rem;
        line-height: 1.6875rem;
        font-weight: bold;
        color: #fff; }
      .contact-form__main .Label:nth-of-type(8),
      .contact-form__main .Label:nth-of-type(9) {
        flex-basis: calc(100% - 2rem); }
      .contact-form__main .Input:nth-of-type(7),
      .contact-form__main .Input:nth-of-type(8) {
        flex-basis: 1rem; }
      .contact-form__main .Label:nth-of-type(1),
      .contact-form__main .Label:nth-of-type(2) {
        order: -13;
        color: #06b400;
        flex-basis: 41.4%; }
      .contact-form__main .Input:nth-of-type(1):not([type="textarea"]):not(.Submit):not(.Textarea):not([type="checkbox"]),
      .contact-form__main .Input:nth-of-type(2):not([type="textarea"]):not(.Submit):not(.Textarea):not([type="checkbox"]) {
        order: -12;
        flex-basis: 41.4%;
        border-color: #06b400; }
      .contact-form__main .Label:nth-of-type(3) {
        order: -11; }
      .contact-form__main .Input:nth-of-type(3) {
        order: -10; }
      .contact-form__main .Label:nth-of-type(4) {
        order: -9; }
      .contact-form__main .Input:nth-of-type(4) {
        order: -8; }
      .contact-form__main .Label:nth-of-type(5) {
        order: -7; }
      .contact-form__main .Input:nth-of-type(5) {
        order: -5; }
      .contact-form__main .Label:nth-of-type(6) {
        order: -3;
        flex-basis: 41.4%; }
      .contact-form__main .Input:nth-of-type(6).Input:nth-of-type(6) {
        order: -1;
        flex-basis: 41.4%; }
      .contact-form__main .Label:nth-of-type(7) {
        order: -4;
        flex-basis: 41.4%; }
      .contact-form__main .Textarea {
        order: -2;
        flex-basis: 41.4%;
        height: 40px; }
      .contact-form__main .Label:nth-of-type(8) {
        order: 6;
        flex-basis: 41.4%;
        text-align: center; }
      .contact-form__main .Input:nth-of-type(8),
      .contact-form__main .Input:nth-of-type(7) {
        order: 2;
        width: 30px;
        height: 30px;
        display: block;
        margin: 0 19% 10px; }
      .contact-form__main .Input:nth-of-type(7) {
        order: 3; }
      .contact-form__main .Label:nth-of-type(9) {
        order: 5;
        flex-basis: 41.4%;
        text-align: center; }
      .contact-form__main .Submit {
        order: 10; } }
  .contact-form .Label {
    font-family: "Ubuntu", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #2e2e2e;
    padding-bottom: 0.5rem; }
  @media (min-width: 822px) {
    .contact-form .Label,
    .contact-form .Input:not(.Submit):not(.Textarea):not([type="checkbox"]) {
      flex-basis: 41.4%; }
    .contact-form .Input:not(.Submit):not(.Textarea):not([type="checkbox"]) {
      height: 40px;
      width: 100%; }
    .contact-form .Input:not(.Submit):not([type="checkbox"]) {
      border-radius: 10px;
      border: 1px solid #9e9e9e;
      outline: none;
      padding: 0 0.5rem;
      margin-bottom: 1.625rem; }
    .contact-form .Input[type="checkbox"] {
      margin-left: 1rem;
      margin-bottom: 0.5rem; }
    .contact-form .Textarea {
      flex-basis: 100%; }
    .contact-form .Submit {
      cursor: pointer;
      border: none;
      padding: 0.875rem 3rem;
      margin: 2.8125rem auto 0 auto;
      border-radius: 0.625rem;
      background: -webkit-linear-gradient(45deg, #55d500, #c9c900);
      background: linear-gradient(45deg, #55d500, #c9c900);
      font-family: "Ubuntu", sans-serif;
      font-size: 1.25rem;
      line-height: 1.6875rem;
      font-weight: bold;
      color: #fff; }
    .contact-form .Label:nth-of-type(8),
    .contact-form .Label:nth-of-type(9) {
      flex-basis: calc(100% - 2rem); }
    .contact-form .Input:nth-of-type(7),
    .contact-form .Input:nth-of-type(8) {
      flex-basis: 1rem; }
    .contact-form .Label:nth-of-type(1),
    .contact-form .Label:nth-of-type(2) {
      order: -8; }
    .contact-form .Input:nth-of-type(1):not([type="textarea"]):not(.Submit):not(.Textarea):not([type="checkbox"]),
    .contact-form .Input:nth-of-type(2):not([type="textarea"]):not(.Submit):not(.Textarea):not([type="checkbox"]) {
      order: -7; }
    .contact-form .Label:nth-of-type(3),
    .contact-form .Label:nth-of-type(4) {
      order: -6; }
    .contact-form .Input:nth-of-type(3),
    .contact-form .Input:nth-of-type(4) {
      order: -5; }
    .contact-form .Label:nth-of-type(5),
    .contact-form .Label:nth-of-type(6).Label:nth-of-type(6) {
      order: -4; }
    .contact-form .Input:nth-of-type(5),
    .contact-form .Input:nth-of-type(6) {
      order: -3; }
    .contact-form .Label:nth-of-type(7) {
      order: -2; }
    .contact-form .Textarea {
      order: -1 !important;
      flex-basis: 100%; }
    .contact-form .Input:nth-of-type(8).Input:nth-of-type(8).Input:nth-of-type(8),
    .contact-form .Input:nth-of-type(7) {
      position: static;
      opacity: 1; }
    .contact-form .Label:nth-of-type(8),
    .contact-form .Input:nth-of-type(8) {
      order: 1; }
    .contact-form .Label:nth-of-type(9),
    .contact-form .Input:nth-of-type(9) {
      order: 2; }
    .contact-form .Submit {
      order: 4; }
    .contact-form {
      grid-row: initial; } }

.app {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh; }

.main {
  flex: 1 1; }


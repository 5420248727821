.contact-us {
  &__header {
    text-align: center;
    padding-top: 2.5rem;
    padding-bottom: 4.25rem;
  }

  &__title {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: bold;
    color: #2E2E2E;
    transition: color 0.3s ease-in-out;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: calc(100% + 0.5rem);
      left: 50%;
      width: 122px;
      height: 7px;
      border-radius: 10px;
      background: linear-gradient(45deg, #55D500, #C9C900);
      transform: translateX(-50%);
    }
  }

  &__subtitle {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: bold;
    color: #2E2E2E;
    padding-top: 2rem;
  }

  &__main {
    display: grid;
    grid-gap: 2.6875rem;
    align-items: center;
  }

  @media (min-width: 426px) {
    &__title {
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
  }

  @media (min-width: 822px) {
    &__main {
      grid-gap: 5.375rem;
      grid-template-columns: repeat(2, 1fr);

      padding-bottom: 8.75rem;
    }
  }

  @media (min-width: 992px) {
    &__title {
      font-size: 3.75rem;
      line-height: 5rem;
    }

    &__main {
      grid-gap: 10.75rem;
    }
  }
}
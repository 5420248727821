.copyright {
  font-family: 'Ubuntu', sans-serif;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: #707070;
  text-align: center;

  padding-top: 2.75rem;
  padding-bottom: 0;

  @media (min-width: 576px) {
    text-align: right;
  }
}
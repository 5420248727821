html {
  box-sizing: border-box;
  font-size: 100%;
}

html,
body {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Ubuntu', 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
  height: auto;
}

button {
  border: none;
}

button:hover {
  cursor: pointer;
}

p {
  padding-bottom: 1.375rem;
}